<template>

    <div class="mr-visual">
      <div class="mr-visual-container">
        <div class="mr-visual-header">
          <v-header />
          <v-card :propsData="projectCode" />
        </div>
        <div class="mr-visual-content">
          <v-mini-card :position="{ left: 530, top: 450 }" :status="'normal'" :list="data.list63" v-if="data.list63.length>0" />
          <v-mini-card :position="{ left: 1313, top: 710 }" :status="'normal'" :list="data.list68" v-if="data.list68.length>0" />
          <v-mini-card :position="{ left: 970, top: 450 }" :status="'normal'" :list="data.list69" v-if="data.list69.length>0" />
          <v-mini-card :position="{ left: 650, top: 265 }" :status="'normal'" :list="data.list70" v-if="data.list70.length>0" />
          <v-mini-card :position="{ left: 1495, top: 310 }" :status="'normal'" :list="data.list71" v-if="data.list71.length>0" />
          <v-switch-card :position="{ left: 565, top: 620 }" :open="sw.openqdf1" :close="sw.closeqdf1"/>
          <v-switch-card :position="{ left: 775, top: 470 }" :open="sw.openqdf2" :close="sw.closeqdf2"/>
          <v-switch-card :position="{ left: 680, top: 770 }" :open="sw.openqdf3" :close="sw.closeqdf3"/>
          <v-switch-card :position="{ left: 290, top: 545 }" :open="sw.openqdf4" :close="sw.closeqdf4"/>
          <v-switch-card :position="{ left: 345, top: 660 }" :open="sw.openqdf5" :close="sw.closeqdf5"/>
          <v-switch-card :position="{ left: 490, top: 650 }" :open="sw.openqdf6" :close="sw.closeqdf6"/>
          <v-switch-card :position="{ left: 1720, top: 510 }" :open="sw.openqdf7" :close="sw.closeqdf7"/>
          <v-switch-card :position="{ left: 1805, top: 610 }" :open="sw.openqdf8" :close="sw.closeqdf8"/>
          <v-switch-simple :position="{ left: 1410, top: 860 }" :open="sw.openxhb1" :close="sw.closexhb1"/>
          <v-switch-simple :position="{ left: 1440, top: 785 }" :open="sw.openxhb2" :close="sw.closexhb2"/>
          <v-switch-simple :position="{ left: 890, top: 500 }" :open="sw.openxhb3" :close="sw.closexhb3"/>
          <v-switch-simple :position="{ left: 485, top: 375 }" :open="sw.openxhb4" :close="sw.closexhb4"/>
          <v-switch-simple :position="{ left: 1695, top: 408 }" :open="sw.openxhb5" :close="sw.closexhb5"/>
          <v-switch-simple :position="{ left: 1190, top: 285 }" :open="sw.openxhb6" :close="sw.closexhb6"/>
          <v-switch-simple :position="{ left: 740, top: 655 }" :open="sw.openzkb1" :close="sw.closezkb1"/>
          <v-switch-simple :position="{ left: 925, top: 605 }" :open="sw.openzkb2" :close="sw.closezkb2"/>
          <v-switch-simple :position="{ left: 805, top: 675 }" :open="sw.openzkb3" :close="sw.closezkb3"/>
          <v-switch-simple :position="{ left: 980, top: 630 }" :open="sw.openzkb4" :close="sw.closezkb4"/>
          <v-switch-simple :position="{ left: 870, top: 690 }" :open="sw.openzkb5" :close="sw.closezkb5"/>
          <v-switch-simple :position="{ left: 1040, top: 650 }" :open="sw.openzkb6" :close="sw.closezkb6"/>
          <v-switch-simple :position="{ left: 1005, top: 791 }" :open="sw.openzkb7" :close="sw.closezkb7"/>
          <v-switch-simple :position="{ left: 1150, top: 746 }" :open="sw.openzkb8" :close="sw.closezkb8"/>
          <v-switch-simple :position="{ left: 1042, top: 310 }" :open="sw.opendev1" :close="sw.closedev1"/>
          <v-switch-simple :position="{ left: 1535, top: 510 }" :open="sw.opendev2" :close="sw.closedev2"/>
          <v-switch-simple :position="{ left: 1285, top: 385 }" :open="sw.opendev3" :close="sw.closedev3"/>
        </div>
        <div class="mr-visual-system">
          <div style="font-size:16px;margin-bottom:5px;margin-left:5px;">系统参数</div>
          <table style="border-width:1px;border-color:white;margin:5px;">
            <tr>
              <td class="gzc-title">预热中</td>
              <td class="gzc-value">{{ system.yrz }}</td>
            </tr>
            <tr>
              <td class="gzc-title">预热完成</td>
              <td class="gzc-value">{{ system.yrwc }}</td>
            </tr>
            <tr>
              <td class="gzc-title">自动运行结束</td>
              <td class="gzc-value">{{ system.overauto }}</td>
            </tr>
            <tr>
              <td class="gzc-title">自检完成</td>
              <td class="gzc-value">{{ system.overself }}</td>
            </tr>
            <tr>
              <td class="gzc-title">当前预热时间</td>
              <td class="gzc-value">{{ system.dqyrsj }}</td>
            </tr>
            <tr>
              <td class="gzc-title">系统运行时间</td>
              <td class="gzc-value">{{ system.xtyxsj }}</td>
            </tr>
            <tr>
              <td class="gzc-title">环境温度</td>
              <td class="gzc-value">{{ system.hjwd }}</td>
            </tr>
          </table>
        </div>
        <div class="mr-visual-device">
          <div style="font-size:16px;margin-bottom:5px;margin-left:5px;">设备列表</div>
          <table style="border-width:1px;border-color:white;margin:5px;">
            <tr v-for="(item,index) in deviceList" :key="index">
              <td class="gzc-title">{{ item.categoryName }}</td>
              <td class="gzc-value" v-if="item.online=='yes'" style="color:green;">在线</td>
              <td class="gzc-value" style="color:gray;" v-else>离线</td>
            </tr>
          </table>
        </div>
        <div class="mr-visual-ganzaocang">
          <div style="font-size:16px;margin-bottom:5px;margin-left:5px;">干燥仓监控</div>
          <table style="border-width:1px;border-color:white;margin:5px;">
            <tr>
              <td class="gzc-title">T1物料温度</td>
              <td class="gzc-value">{{ ganzc.temperature1 }}</td>
            </tr>
            <tr>
              <td class="gzc-title">T2物料温度</td>
              <td class="gzc-value">{{ ganzc.temperature2 }}</td>
            </tr>
            <tr>
              <td class="gzc-title">T3物料温度</td>
              <td class="gzc-value">{{ ganzc.temperature3 }}</td>
            </tr>
            <tr>
              <td class="gzc-title">T4物料温度</td>
              <td class="gzc-value">{{ ganzc.temperature4 }}</td>
            </tr>
            <tr>
              <td class="gzc-title">T5散热板温度</td>
              <td class="gzc-value">{{ ganzc.temperature5 }}</td>
            </tr>
            <tr>
              <td class="gzc-title">T6散热板温度</td>
              <td class="gzc-value">{{ ganzc.temperature6 }}</td>
            </tr>
            <tr>
              <td class="gzc-title">T7散热板温度</td>
              <td class="gzc-value">{{ ganzc.temperature7 }}</td>
            </tr>
            <tr>
              <td class="gzc-title">T8散热板温度</td>
              <td class="gzc-value">{{ ganzc.temperature8 }}</td>
            </tr>
            <tr>
              <td class="gzc-title">RH1干燥仓湿度</td>
              <td class="gzc-value">{{ ganzc.rh1sd }}</td>
            </tr>
            <tr>
              <td class="gzc-title">RH1干燥仓温度</td>
              <td class="gzc-value">{{ ganzc.rh1wd }}</td>
            </tr>
            <tr>
              <td class="gzc-title">RH2干燥仓湿度</td>
              <td class="gzc-value">{{ ganzc.rh2sd }}</td>
            </tr>
            <tr>
              <td class="gzc-title">RH2干燥仓温度</td>
              <td class="gzc-value">{{ ganzc.rh2wd }}</td>
            </tr>
            <tr>
              <td class="gzc-title">H1干燥仓压力</td>
              <td class="gzc-value">{{ ganzc.h1ya }}</td>
            </tr>
            <tr>
              <td class="gzc-title">H2干燥仓压力</td>
              <td class="gzc-value">{{ ganzc.h2ya }}</td>
            </tr>
            <tr>
              <td class="gzc-title">H3主泵口压力</td>
              <td class="gzc-value">{{ ganzc.h3ya }}</td>
            </tr>
          </table>
        </div>
        <div class="menu">
          <div style="width:14%;">&nbsp;</div>
          <div class="menu-main"/>
          <div class="menu-yunxing"/>
          <div class="menu-guzhang" @click="backHome"><img :src="main" class="icon"/>返回项目主页</div>
          <div class="menu-shuju"/>
          <div class="menu-alarm">
            <div style="width:2%;height:64px;background:red;" />
            <div style="width:98%;height:64px;display:flex;flex-direction:column;">
              <div class="alarm1">
                <div class="alarm11"><img :src="alarm" class="img-alarm" />报警信息</div>
                <div class="alarm12">
                  今日新增<span style="color:red;margin-left:5px;font-weight:bold;font-size:18px;">{{ today }}</span>
                </div>
              </div>
              <div class="alarm2" v-if="faultItem != null" @click="goGZ">
                <div class="alarm21">{{ faultItem.name }}&nbsp;&nbsp;{{ faultItem.createdAt }} </div>
                <div class="alarm22">更多<img :src="more" class="img-more" /></div>
              </div>
              <div class="alarm2" v-else>
                <div class="alarm21">暂无上报故障...</div>
              </div>
            </div>
          </div>
        </div>
        <div class="title">宁夏链杞烘干车间智慧控制系统</div>
      </div>
    </div>

</template>

<script lang="js">
import VHeader from "./components/v-header.vue";
import VCard from "./components/v-card.vue";
import vMiniCard from "./components/v-mini-card.vue";
import vSwitchCard from "./components/v-switch-card.vue";
import vSwitchSimple from "./components/v-switch-simple.vue";
import store from "@/store";

export default {
  name: "nxhg",
  components: {
    VCard,
    VHeader,
    vMiniCard,
    vSwitchCard,
    vSwitchSimple
  },
  data() {
    return {
      first: true,
      retry: 0,
      deviceList: [],
      socket: null,
      data: {
        list63: [],
        list68: [],
        list69: [],
        list70: [],
        list71: [],
      },
      sw: {
        openqdf1: "0",
        closeqdf1: "0",
        openqdf2: "0",
        closeqdf2: "0",
        openqdf3: "0",
        closeqdf3: "0",
        openqdf4: "0",
        closeqdf4: "0",
        openqdf5: "0",
        closeqdf5: "0",
        openqdf6: "0",
        closeqdf6: "0",
        openqdf7: "0",
        closeqdf7: "0",
        openqdf8: "0",
        closeqdf8: "0",
        openxhb1: "0",
        closexhb1: "0",
        openxhb2: "0",
        closexhb2: "0",
        openxhb3: "0",
        closexhb3: "0",
        openxhb4: "0",
        closexhb4: "0",
        openxhb5: "0",
        closexhb5: "0",
        openxhb6: "0",
        closexhb6: "0",
        openzkb1: "0",
        closezkb1: "0",
        openzkb2: "0",
        closezkb2: "0",
        openzkb3: "0",
        closezkb3: "0",
        openzkb4: "0",
        closezkb4: "0",
        openzkb5: "0",
        closezkb5: "0",
        openzkb6: "0",
        closezkb6: "0",
        openzkb6: "0",
        closezkb6: "0",
        openzkb7: "0",
        closezkb7: "0",
        openzkb8: "0",
        closezkb8: "0",
        penzkb9: "0",
        opendev1: "0",
        closedev1: "0",
        opendev2: "0",
        closedev2: "0",
        opendev3: "0",
        closedev3: "0",
      },
      system: {
        yrz: " - ",
        yrwc: " - ",
        overauto: " - ",
        overself: " - ",
        dqyrsj: " - ",
        xtyxsj: " - ",
        hjwd: " - ",
      },
      ganzc: {
        temperature1: " - ",
        temperature2: " - ",
        temperature3: " - ",
        temperature4: " - ",
        temperature5: " - ",
        temperature6: " - ",
        temperature7: " - ",
        temperature8: " - ",
        rh1sd: " - ",
        rh1wd: " - ",
        rh2sd: " - ",
        rh2wd: " - ",
        h1ya: " - ",
        h2ya: " - ",
        h3ya: " - ",
      },
      projectCode: "",
      main: require("@/assets/images/nxhg/home.png"),
      yunxing: require("@/assets/images/nxhg/yunxing.png"),
      guzhang: require("@/assets/images/nxhg/guzhang.png"),
      shuju: require("@/assets/images/nxhg/shuju.png"),
      alarm: require("@/assets/images/nxhg/zhuyi.png"),
      more: require("@/assets/images/nxhg/more.png"),
      today: 0,
      faultItem: null
    }
  },
  computed: {},
  mounted() {
    this.projectCode = this.$route.query.projectCode;
    this.first=true;
    this.initWebsocket();
    this.staticFaults();
    this.getDeviceList();
    this.getProjectDetail();
  },
  methods: {
    parseData(json) {
      if (json['三号冷水箱温度']!=null) {
        var item={
          chinese: '三号冷水箱温度',
          value: json['三号冷水箱温度'],
          unit: '℃'
        };
        this.data.list71=[item];
      }
      if (json['二号冷水箱温度']!=null) {
        var item1={
          chinese: '二号冷水箱温度',
          value: json['二号冷水箱温度'],
          unit: '℃'
        };
        var item2={
          chinese: '二号冷水箱液位',
          value: json['二号冷水箱液位'],
          unit: '%'
        };
        this.data.list70=[item1,item2];
      }
      if (json['一号冷水箱温度']!=null) {
        var item1={
          chinese: '一号冷水箱温度',
          value: json['一号冷水箱温度'],
          unit: '℃'
        };
        var item2={
          chinese: '一号冷水箱液位',
          value: json['一号冷水箱液位'],
          unit: '%'
        };
        this.data.list69=[item1,item2];
      }
      if (json['热水箱温度']!=null) {
        var item={
          chinese: '热水箱温度',
          value: json['热水箱温度'],
          unit: '℃'
        };
        this.data.list68=[item];
      }
      if (json['RH3主泵口湿度']!=null) {
        var item1={
          chinese: 'RH3主泵口湿度',
          value: json['RH3主泵口湿度'],
          unit: 'RH%'
        };
        var item2={
          chinese: 'RH3主泵口温度',
          value: json['RH3主泵口温度']+'℃',
          unit: '℃'
        };
        var item3={
          chinese: 'H3主泵口压力',
          value: json['H3主泵口压力'],
          unit: 'KPa'
        };
        this.data.list63=[item1,item2,item3];
      }
      if (json['空气源热泵运行指示']!=null) {
        if (json['空气源热泵运行指示']=='1') {
          this.sw.opendev3="1";
          this.sw.closedev3="0";
        } else if (json['空气源热泵运行指示']=='0') {
          this.sw.opendev3="0";
          this.sw.closedev3="1";
        } else {
          this.sw.opendev3="0";
          this.sw.closedev3="0";
        }
      }
      if (json['能量交换机组运行指示']!=null) {
        if (json['能量交换机组运行指示']=='1') {
          this.sw.opendev2="1";
          this.sw.closedev2="0";
        } else if (json['能量交换机组运行指示']=='0') {
          this.sw.opendev2="0";
          this.sw.closedev2="1";
        } else {
          this.sw.opendev2="0";
          this.sw.closedev2="0";
        }
      }
      if (json['冷水机组运行指示']!=null) {
        if (json['冷水机组运行指示']=='1') {
          this.sw.opendev1="1";
          this.sw.closedev1="0";
        } else if (json['冷水机组运行指示']=='0') {
          this.sw.opendev1="0";
          this.sw.closedev1="1";
        } else {
          this.sw.opendev1="0";
          this.sw.closedev1="0";
        }
      }
      if (json['2BV5131水环真空泵2运行指示']!=null) {
        if (json['2BV5131水环真空泵2运行指示']=='1') {
          this.sw.openzkb8="1";
          this.sw.closezkb8="0";
        } else if (json['2BV5131水环真空泵2运行指示']=='0') {
          this.sw.openzkb8="0";
          this.sw.closezkb8="1";
        } else {
          this.sw.openzkb8="0";
          this.sw.closezkb8="0";
        }
      }
      if (json['2BV5131水环真空泵1运行指示']!=null) {
        if (json['2BV5131水环真空泵1运行指示']=='1') {
          this.sw.openzkb7="1";
          this.sw.closezkb7="0";
        } else if (json['2BV5131水环真空泵1运行指示']=='0') {
          this.sw.openzkb7="0";
          this.sw.closezkb7="1";
        } else {
          this.sw.openzkb7="0";
          this.sw.closezkb7="0";
        }
      }
      if (json['ZJP600罗茨真空泵1运行指示']!=null) {
        if (json['ZJP600罗茨真空泵1运行指示']=='1') {
          this.sw.openzkb1="1";
          this.sw.closezkb1="0";
        } else if (json['ZJP600罗茨真空泵1运行指示']=='0') {
          this.sw.openzkb1="0";
          this.sw.closezkb1="1";
        } else {
          this.sw.openzkb1="0";
          this.sw.closezkb1="0";
        }
      }
      if (json['ZJP600罗茨真空泵2运行指示']!=null) {
        if (json['ZJP600罗茨真空泵2运行指示']=='1') {
          this.sw.openzkb2="1";
          this.sw.closezkb2="0";
        } else if (json['ZJP600罗茨真空泵2运行指示']=='0') {
          this.sw.openzkb2="0";
          this.sw.closezkb2="1";
        } else {
          this.sw.openzkb2="0";
          this.sw.closezkb2="0";
        }
      }
      if (json['ZJP300罗茨真空泵1运行指示']!=null) {
        if (json['ZJP300罗茨真空泵1运行指示']=='1') {
          this.sw.openzkb3="1";
          this.sw.closezkb3="0";
        } else if (json['ZJP300罗茨真空泵1运行指示']=='0') {
          this.sw.openzkb3="0";
          this.sw.closezkb3="1";
        } else {
          this.sw.openzkb3="0";
          this.sw.closezkb3="0";
        }
      }
      if (json['ZJP300罗茨真空泵2运行指示']!=null) {
        if (json['ZJP300罗茨真空泵2运行指示']=='1') {
          this.sw.openzkb4="1";
          this.sw.closezkb4="0";
        } else if (json['ZJP300罗茨真空泵2运行指示']=='0') {
          this.sw.openzkb4="0";
          this.sw.closezkb4="1";
        } else {
          this.sw.openzkb4="0";
          this.sw.closezkb4="0";
        }
      }
      if (json['ZJP150罗茨真空泵1运行指示']!=null) {
        if (json['ZJP150罗茨真空泵1运行指示']=='1') {
          this.sw.openzkb5="1";
          this.sw.closezkb5="0";
        } else if (json['ZJP150罗茨真空泵1运行指示']=='0') {
          this.sw.openzkb5="0";
          this.sw.closezkb5="1";
        } else {
          this.sw.openzkb5="0";
          this.sw.closezkb5="0";
        }
      }
      if (json['ZJP150罗茨真空泵2运行指示']!=null) {
        if (json['ZJP150罗茨真空泵2运行指示']=='1') {
          this.sw.openzkb6="1";
          this.sw.closezkb6="0";
        } else if (json['ZJP150罗茨真空泵2运行指示']=='0') {
          this.sw.openzkb6="0";
          this.sw.closezkb6="1";
        } else {
          this.sw.openzkb6="0";
          this.sw.closezkb6="0";
        }
      }
      if (json['1#热水循环泵运行指示']!=null) {
        if (json['1#热水循环泵运行指示']=='1') {
          this.sw.openxhb1="1";
          this.sw.closexhb1="0";
        } else if (json['1#热水循环泵运行指示']=='0') {
          this.sw.openxhb1="0";
          this.sw.closexhb1="1";
        } else {
          this.sw.openxhb1="0";
          this.sw.closexhb1="0";
        }
      }
      if (json['2#水循环泵运行指示']!=null) {
        if (json['2#水循环泵运行指示']=='1') {
          this.sw.openxhb2="1";
          this.sw.closexhb2="0";
        } else if (json['2#热水循环泵运行指示']=='0') {
          this.sw.openxhb2="0";
          this.sw.closexhb2="1";
        } else {
          this.sw.openxhb2="0";
          this.sw.closexhb2="0";
        }
      }
      if (json['3#真空机组冷却循环泵运行指示']!=null) {
        if (json['3#真空机组冷却循环泵运行指示']=='1') {
          this.sw.openxhb3="1";
          this.sw.closexhb3="0";
        } else if (json['3#真空机组冷却循环泵运行指示']=='0') {
          this.sw.openxhb3="0";
          this.sw.closexhb3="1";
        } else {
          this.sw.openxhb3="0";
          this.sw.closexhb3="0";
        }
      }
      if (json['4#冷凝器循环泵运行指示']!=null) {
        if (json['4#冷凝器循环泵运行指示']=='1') {
          this.sw.openxhb4="1";
          this.sw.closexhb4="0";
        } else if (json['4#冷凝器循环泵运行指示']=='0') {
          this.sw.openxhb4="0";
          this.sw.closexhb4="1";
        } else {
          this.sw.openxhb4="0";
          this.sw.closexhb4="0";
        }
      }
      if (json['5#混水循环泵运行指示']!=null) {
        if (json['5#混水循环泵运行指示']=='1') {
          this.sw.openxhb5="1";
          this.sw.closexhb5="0";
        } else if (json['5#混水循环泵运行指示']=='0') {
          this.sw.openxhb5="0";
          this.sw.closexhb5="1";
        } else {
          this.sw.openxhb5="0";
          this.sw.closexhb5="0";
        }
      }
      if (json['6#换冷循环泵运行指示']!=null) {
        if (json['6#换冷循环泵运行指示']=='1') {
          this.sw.openxhb6="1";
          this.sw.closexhb6="0";
        } else if (json['6#换冷循环泵运行指示']=='0') {
          this.sw.openxhb6="0";
          this.sw.closexhb6="1";
        } else {
          this.sw.openxhb6="0";
          this.sw.closexhb6="0";
        }
      }
      if (json['T1物料温度']!=null) {
        this.ganzc.temperature1=json['T1物料温度']+'℃';
      }
      if (json['T2物料温度']!=null) {
        this.ganzc.temperature2=json['T2物料温度']+'℃';
      }
      if (json['T3物料温度']!=null) {
        this.ganzc.temperature3=json['T3物料温度']+'℃';
      }
      if (json['T4物料温度']!=null) {
        this.ganzc.temperature4=json['T4物料温度']+'℃';
      }
      if (json['T5物料温度']!=null) {
        this.ganzc.temperature5=json['T5物料温度']+'℃';
      }
      if (json['T6物料温度']!=null) {
        this.ganzc.temperature6=json['T6物料温度']+'℃';
      }
      if (json['T7物料温度']!=null) {
        this.ganzc.temperature7=json['T7物料温度']+'℃';
      }
      if (json['T8物料温度']!=null) {
        this.ganzc.temperature8=json['T8物料温度']+'℃';
      }
      if (json['RH1干燥仓湿度']!=null) {
        this.ganzc.rh1sd=json['RH1干燥仓湿度']+"RH%";
      }
      if (json['RH1干燥仓温度']!=null) {
        this.ganzc.rh1wd=json['RH1干燥仓温度']+'℃';
      }
      if (json['RH2干燥仓湿度']!=null) {
        this.ganzc.rh2sd=json['RH2干燥仓湿度']+"RH%";
      }
      if (json['RH2干燥仓温度']!=null) {
        this.ganzc.rh2wd=json['RH2干燥仓温度']+'℃';
      }
      if (json['H1干燥仓压力']!=null) {
        this.ganzc.h1ya=json['H1干燥仓压力']+"KPa";
      }
      if (json['H2干燥仓压力']!=null) {
        this.ganzc.h2ya=json['H2干燥仓压力']+"KPa";
      }
      if (json['H3主泵口压力']!=null) {
        this.ganzc.h3ya=json['H3主泵口压力']+"KPa";
      }
      if (json['预热中']!=null) {
        if (json['预热中']=='0') {
          this.system.yrz=json['停止'];
        } else if (json['预热中']=='1') {
          this.system.yrz=json['运行'];
        } else {
          this.system.yrz=" - ";
        }
      }
      if (json['预热完成']!=null) {
        if (json['预热完成']=='0') {
          this.system.yrwc=json['停止'];
        } else if (json['预热完成']=='1') {
          this.system.yrwc=json['运行'];
        } else {
          this.system.yrwc=" - ";
        }
      }
      if (json['自动运行结束']!=null) {
        if (json['自动运行结束']=='0') {
          this.system.overauto=json['停止'];
        } else if (json['自动运行结束']=='1') {
          this.system.overauto=json['运行'];
        } else {
          this.system.overauto=" - ";
        }
      }
      if (json['自检完成']!=null) {
        if (json['自检完成']=='0') {
          this.system.overself=json['停止'];
        } else if (json['自检完成']=='1') {
          this.system.overself=json['运行'];
        } else {
          this.system.overself=" - ";
        }
      }
      if (json['当前预热时间']!=null) {
        this.system.dqyrsj=json['当前预热时间']+"Min";
      }
      if (json['系统运行时间']!=null) {
        this.system.xtyxsj=json['系统运行时间']+"Min";
      }
      if (json['环境温度']!=null) {
        this.system.hjwd=json['环境温度']+'℃';
      }
      if (json['A组真空隔断阀开指示']!=null) {
        if (json['A组真空隔断阀开指示']=='1') {
          this.sw.openqdf1="1";
        } else {
          this.sw.openqdf1="0";
        } 
      }
      if (json['A组真空隔断阀关指示']!=null) {
        if (json['A组真空隔断阀关指示']=='1') {
          this.sw.closeqdf1="1";
        } else {
          this.sw.closeqdf1="0";
        }
      }
      if (json['B组真空隔断阀开指示']!=null) {
        if (json['B组真空隔断阀开指示']=='1') {
          this.sw.openqdf2="1";
        } else {
          this.sw.openqdf2="0";
        } 
      }
      if (json['B组真空隔断阀关指示']!=null) {
        if (json['B组真空隔断阀关指示']=='1') {
          this.sw.closeqdf2="1";
        } else {
          this.sw.closeqdf2="0";
        }
      }
      if (json['干燥仓进气阀开指示']!=null) {
        if (json['干燥仓进气阀开指示']=='1') {
          this.sw.openqdf3="1";
        } else {
          this.sw.openqdf3="0";
        } 
      }
      if (json['干燥仓进气阀关指示']!=null) {
        if (json['干燥仓进气阀关指示']=='1') {
          this.sw.closeqdf3="1";
        } else {
          this.sw.closeqdf3="0";
        }
      }
      if (json['排水真空隔断阀开指示']!=null) {
        if (json['排水真空隔断阀开指示']=='1') {
          this.sw.openqdf4="1";
        } else {
          this.sw.openqdf4="0";
        } 
      }
      if (json['排水真空隔断阀关指示']!=null) {
        if (json['排水真空隔断阀关指示']=='1') {
          this.sw.closeqdf4="1";
        } else {
          this.sw.closeqdf4="0";
        }
      }
      if (json['排水进气阀开指示']!=null) {
        if (json['排水进气阀开指示']=='1') {
          this.sw.openqdf5="1";
        } else {
          this.sw.openqdf5="0";
        } 
      }
      if (json['排水进气阀关指示']!=null) {
        if (json['排水进气阀关指示']=='1') {
          this.sw.closeqdf5="1";
        } else {
          this.sw.closeqdf5="0";
        }
      }
      if (json['排水阀开指示']!=null) {
        if (json['排水阀开指示']=='1') {
          this.sw.openqdf6="1";
        } else {
          this.sw.openqdf6="0";
        } 
      }
      if (json['排水阀关指示']!=null) {
        if (json['排水阀关指示']=='1') {
          this.sw.closeqdf6="1";
        } else {
          this.sw.closeqdf6="0";
        }
      }
      if (json['7#混水阀开指示']!=null) {
        if (json['7#混水阀开指示']=='1') {
          this.sw.openqdf7="1";
        } else {
          this.sw.openqdf7="0";
        } 
      }
      if (json['7#混水阀关指示']!=null) {
        if (json['7#混水阀关指示']=='1') {
          this.sw.closeqdf7="1";
        } else {
          this.sw.closeqdf7="0";
        }
      }
      if (json['8#混水阀开指示']!=null) {
        if (json['8#混水阀开指示']=='1') {
          this.sw.openqdf8="1";
        } else {
          this.sw.openqdf8="0";
        } 
      }
      if (json['8#混水阀关指示']!=null) {
        if (json['8#混水阀关指示']=='1') {
          this.sw.closeqdf8="1";
        } else {
          this.sw.closeqdf8="0";
        }
      }
    },
    getDeviceList() {
      this.retry=0;
      this.request.deviceList({ project: this.projectCode }).then((res) => {
        this.deviceList = res.data || [];
        for (var i = 0; i < this.deviceList.length; i++) {
          if (this.deviceList[i].categoryName=="PLC") {
            var item=this.deviceList[i];
            if (this.first==true) {
              this.first=false;
              var params1={
                "command":"subscribe",
                "deviceId": item.id
              }
              this.sendMessage(JSON.stringify(params1));
            }
            if (item.online=="yes") {
              var params2={
                "command":"alldata",
                "deviceId": item.id,
                "gateway": item.gateway,
                "address": item.address,
              }
              this.sendMessage(JSON.stringify(params2));
            }
            if (i!=0) {
              this.deviceList[i]=this.deviceList[0];
              this.deviceList[0]=item;
            }
          }
        }
        setTimeout(()=>{
          this.getDeviceList();
        },60*1000);
      });
    },
    initWebsocket() {
      if (typeof (WebSocket) == "undefined") {
          console.log("您的浏览器不支持WebSocket");
      } else {
          console.log("您的浏览器支持WebSocket");
          //实现化WebSocket对象，指定要连接的服务器地址与端口  建立连接
          var wssURL="wss://saas-cn.moreinno.com/websocket/" + localStorage.getItem('token');
          console.log("wsURL-->" + wssURL);
          this.socket = new WebSocket(wssURL);
          //打开事件
          this.socket.onopen = function () {
            console.log("Socket 已打开");
          };
          //获得消息事件
          const that=this;
          this.socket.onmessage = function (msg) {
            console.log(msg);
            let json=JSON.parse(msg.data);
            if (json!=null&&Object.keys(json).length>10) {
              that.parseData(json);
            }
          };
          //关闭事件
          this.socket.onclose = function () {
            console.log("Socket已关闭");
          };
          //发生了错误事件
          this.socket.onerror = function () {
            alert("Socket发生了错误");
          }
      }
    },
    getProjectDetail() {
      this.request.projectInfo({ project: this.projectCode }).then(res => {
        if (res.code == 0) {
          store.commit("setProjectCurrentRowData", res.data);
        }
      });
    },
    sendMessage(msg) {
        if (typeof (WebSocket) == "undefined") {
            console.log("您的浏览器不支持WebSocket");
        } else {
          if (this.socket.readyState==WebSocket.OPEN) {
            this.socket.send(msg);
            console.log("socket.send "+msg);
          } else {
            this.retry++;
            console.log("socketState="+this.socket.readyState);
            if (this.retry<10) {
              setTimeout(() => {
                this.sendMessage(msg);
              },1000);
            }
          }
        }
    },
    staticFaults() {
      this.request.staticFaults({ project: this.projectCode }).then(res => {
        if (res.code == 0) {
          this.today = res.data.today;
          if (res.data.deviceFaults.length > 0) {
            this.faultItem = res.data.deviceFaults[0];
            this.faultItem.createdAt = this.faultItem.createdAt.substr(5, 11);
          } else {
            this.faultItem = null;
          }
        }
      });
    },
    goYX() {
      this.$router.push({
        path: "/yunxing",
        query: {
          projectCode: this.projectCode
        },
      });
    },
    goGZ() {
      this.$router.push({
        path: "/guzhang",
        query: {
          projectCode: this.projectCode
        },
      });
    },
    goSJ() {
      this.$router.push({
        path: "/shuju",
        query: {
          projectCode: this.projectCode
        },
      });
    },
    backHome() {
      this.$router.replace({
        path: "/project/proinfo/info",
        query: {
          projectCode: this.projectCode
        },
      });
    },
  }
}
</script>
<style scoped lang="scss">
table {
  border-collapse: collapse;
  border-radius: 5px;
}
table tr td {
  border: 0.5px solid lightcoral;
}
td {
  padding: 2px;
}
.mr-visual {
  width: 1920px;
  height: 1080px;
  background: url("~@/assets/images/nxhg/bg.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;

  .mr-visual-container {
    margin-top: -90px;
    width: 1920px;
    height: 1294px;
    background: url("~@/assets/images/nxhg/device.png") no-repeat;
    background-size: 100% 100%;
  }

  .mr-visual-header {
    height: 52px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background: black;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 24px;
    box-sizing: border-box;
  }

  .mr-visual-content {
    position: relative;
  }

  .mr-visual-system {
    position: fixed;
    background: linear-gradient(pink, white);
    border-radius: 5px;
    color: black;
    padding: 5px;
    left: 20px;
    top: 80px;
  }

  .mr-visual-ganzaocang {
    position: fixed;
    background: linear-gradient(white, pink);
    border-radius: 5px;
    color: black;
    padding: 5px;
    left: 20px;
    bottom: 20px;
  } 

  .mr-visual-device {
    position: fixed;
    background: linear-gradient(pink, white);
    border-radius: 5px;
    color: black;
    padding: 5px;
    right: 20px;
    top: 80px;
  }

  .gzc-title {
    font-size:14px;
    padding-left:5px;
    padding-right:5px;
  }

  .gzc-value {
    font-size:14px;
    padding-left:5px;
    padding-right:5px;
  }

  .title {
    width: 100%;
    height: 52px;
    line-height: 52px;
    text-align: center;
    color: white;
    font-size: 20px;
    position: fixed;
    z-index: 1000;
    top: 0;
  }

  .menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 64px;
    position: absolute;
    bottom: 25px;
    z-index: 200;
  }

  .menu-main {
    width: 9%;
    height: 42px;
    margin-left: 3.5%;
    margin-right: 3.5%;
    line-height: 42px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-yunxing {
    width: 9%;
    height: 42px;
    margin-left: 3.5%;
    margin-right: 3.5%;
    line-height: 42px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-guzhang {
    width: 9%;
    height: 42px;
    margin-left: 3.5%;
    margin-right: 3.5%;
    line-height: 42px;
    text-align: center;
    background: #68A7FC;
    border-radius: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-shuju {
    width: 9%;
    height: 42px;
    margin-left: 3.5%;
    margin-right: 3.5%;
    line-height: 42px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }

  .menu-alarm {
    width: 17%;
    height: 64px;
    margin-left: 3%;
    margin-right: 2%;
    line-height: 64px;
    background: linear-gradient(to right, #FFA5A5, white);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .alarm1 {
    width: 100%;
    height: 34px;
    display: flex;
    align-items: center;
  }

  .img-alarm {
    margin-left: 6px;
    margin-right: 6px;
    width: 24px;
    height: 24px;
  }

  .alarm11 {
    width: 60%;
    height: 34px;
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  .alarm12 {
    width: 35%;
    height: 34px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .alarm2 {
    width: 94%;
    height: 30px;
    margin-left: 3%;
    margin-right: 3%;
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  .alarm21 {
    width: 80%;
    height: 30px;
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  .alarm22 {
    width: 20%;
    height: 34px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .img-more {
    margin-left: 6px;
    margin-right: 6px;
    width: 6px;
  }
}
</style>
