<template>
    <div class="card-container" :style="{ left: position.left + 'px', top: position.top + 'px' }">
        <div class="card-content">
            <div class="view" >
                <div class="cycle" :class="open=='1' ? 'green' : ''"></div>
                <div class="" :class="open=='1' ? 'green-text' : 'gray-text'">开</div>
            </div>
            <div class="view" >
                <div class="cycle" :class="close=='1' ? 'green' : ''"></div>
                <div :class="close=='1' ? 'green-text' : 'gray-text'">关</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'vSwitchCard',
    props: {
        position: {
            type: Object,
            default() {
                return {
                    left: 0,
                    top: 0
                }
            }
        },
        open: {
            type: String,
            default: "0"
        },
        close: {
            type: String,
            default: "0"
        }
    },
    data() {
        return {

        }
    }
}

</script>
<style lang="scss" scoped>
.card-container {
    position: absolute;


    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    .card-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        color: #7a7a7a;
        text-align: center;
        padding: 5px 4px;

        .view {
            margin: 0 8px;

            .cycle {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: #7a7a7a;
                margin: 0 auto;
            }

            .green {
                background: #1EB969;
            }

            .green-text{
                color: #1EB969;
                font-size: 10px;
            }

            .gray-text {
                font-size: 10px;
            }
        }

    }
}
</style>