<template>
  <div class="v-header">
    <img :src="logo" style="height:36px;"/>
  </div>
</template>

<script lang="js">
export default {
  name: 'VHeader',
  components: {
    
  },
  data() {
    return {
      logo: require("@/assets/images/nxhg/logo.png"),
    }
  },
  computed: {},
  mounted() {},
  methods: {
    
  }
}
</script>
<style scoped lang="scss">
.v-header {
  height: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}
</style>
