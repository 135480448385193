<template>
    <div class="card-container" :class="status == 'alarm' ? 'redbg' : ''" :style="{
        top: position.top + 'px',
        left: position.left + 'px'
    }">
        <div class="card-content" v-for="(item, index) in list" :key="index">
            <div class="name">{{ item.chinese }}</div>
            <div class="value">{{ item.value }}</div>
            <div class="unit">{{ item.unit }}</div>
        </div>
        <div class="more" v-if="status == 'alarm'">
            <img :src="alarm" alt="">
            <div class="text">暂无上报故障</div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'VCard',
    props: {
        position: {
            type: Object,
            default() {
                return {
                    left: 0,
                    top: 0
                }
            }
        },
        status: {
            type: String,
            default: 'normal'
        },
        list: {
            type: Array,
            default() {
                return [
                    {
                        chinese: '温度',
                        value: "0",
                        unit: '℃'
                    }
                ]
            }
        },
    },
    data() {
        return {
            alarm: require("@/assets/images/nxhg/zy.png"),
            comPosition: {
                left: 0,
                top: 0
            }


        }
    },
    mounted() {
       
    },

    methods: {
    }
}
</script>
<style lang="scss" scoped>
.card-container {
    background: #fff;
    border-radius: 5px;
    position: absolute;
    min-width: 90px;

    padding: 5px 10px;

    .card-content {
        display: flex;
        flex-direction: row;

        div {
            flex-shrink: 0;
            flex-grow: 1;
        }

        .name,
        .unit {
            font-size: 12px;
            font-weight: normal;
            line-height: 24px;
            text-align: left;
            letter-spacing: 0em;
            font-variation-settings: "opsz" auto;
            color: #3D3D3D;
        }


        .value {
            font-size: 16px;
            font-weight: bold;
            line-height: 24px;
            text-align: right;
            letter-spacing: 0em;
            font-variation-settings: "opsz" auto;
            font-feature-settings: "kern" on;
            color: #3D3D3D;
        }

    }

    .more {
        display: flex;

        .text {
            max-width: 150px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            font-size: 12px;
            font-weight: normal;
            line-height: 18px;
            text-align: right;
            letter-spacing: 0em;
            font-variation-settings: "opsz" auto;
            color: #3D3D3D;
            padding: 0 4px;
        }
    }
}

.redbg {
    background: linear-gradient(to right, #FFA5A5, white);
}
</style>